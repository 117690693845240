import { createTheme } from '@mui/material';

const theme = createTheme({
  direction: 'rtl',
  palette: {
    primary: {
      main: '#005891',
    },
    background: { default: '#eceff1' },
  },
});

export default theme;
