import { Backdrop, CircularProgress } from '@mui/material';

import { observer } from 'mobx-react';
import React from 'react';
import authStore from '../stores/AuthStore';
import verifyStore from '../stores/VerifyStore';

const Loader = () => {
  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={verifyStore.loading || authStore.loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default observer(Loader);
