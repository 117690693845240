import { Navigate, useNavigate } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';

import Btn from '../components/Btn';
import React from 'react';
import { ThumbUpAltRounded } from '@mui/icons-material';
import verifyStore from '../stores/VerifyStore';
import { observer } from 'mobx-react';

const Details = () => {
  const navigate = useNavigate();
  return verifyStore?.qrRes ? (
    <Stack
      sx={{
        maxHeight: '100%',
        observer: 'scroll',
        padding: 5,
      }}
      justifyContent="center"
      alignItems="center"
      gap={1}
    >
      <Stack
        justifyContent="center"
        direction="column"
        alignItems="center"
        maxHeight="100%"
        borderRadius={5}
        padding={5}
        gap={5}
      >
        <ThumbUpAltRounded sx={{ fontSize: 80 }} color="success" />
        <Typography variant="h5" color="success.light">
          تم التحقق من الشهادة
        </Typography>
      </Stack>
      <Btn borderRadius={5} onSubmit={() => verifyStore.dlQrData()}>
        تحميل
      </Btn>
      <Btn
        borderRadius={5}
        onSubmit={() => verifyStore.clearQrData(navigate)}
        outline
      >
        الرجوع
      </Btn>
    </Stack>
  ) : (
    <Navigate to="/" replace />
  );
};

export default observer(Details);
