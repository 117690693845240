import { InfoOutlined, SearchOutlined } from '@mui/icons-material';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import Btn from '../components/Btn';
import PopUp from '../components/PopUp';
import verifyStore from '../stores/VerifyStore';
import FileUploader from './FileUploader';
import InformationDetails from './InformationDetails';
import Search from './Search';
import QrScanner from './QrScanner';

const MainPage = () => {
  const [search, setSearch] = useState(false);
  const navigate = useNavigate();

  const handleSearch = () => {
    verifyStore.scan = false;
    setSearch(!search);
  };

  return (
    <Stack height="100%" alignItems="center" gap={2} textAlign="center" p={2}>
      <Typography textAlign="center">
        يمكنكم التحقق من صلاحية الشهادة عن طريق رفع الملف
      </Typography>
      <Typography textAlign="center">
        أو عن طريق مسح رمز QR باستخدام الكاميرا
      </Typography>
      <Divider />
      <Divider />
      <PopUp
        BtnLabel="التعليمات"
        BtnIcon={<InfoOutlined />}
        title="لضمان سرعة مسح الرمز وسهولة الاستخدام، يرجى اتباع الارشادات التالية:"
      >
        <InformationDetails />
      </PopUp>
      <Divider />
      <Divider />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap={2}
      >
        <QrScanner />
        <FileUploader
          onFileSelectError={(err) => {
            console.log(err);
            verifyStore.uploadedFile = err.error;
          }}
          onFileSelectSuccess={(file) => {
            verifyStore.processFile(file, navigate);
          }}
        />
        <Btn borderRadius={15} onSubmit={handleSearch}>
          <SearchOutlined />
          بحث
        </Btn>
      </Grid>
      {search && <Search />}
    </Stack>
  );
};

export default observer(MainPage);
