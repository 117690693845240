import { MenuRounded } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  Card,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  styled,
} from '@mui/material';
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import theme from '../theme';
import authStore from '../stores/AuthStore';
import pages from '../assets/data/pages';
import { useState } from 'react';
import { ReactComponent as PifssLogo } from '../assets/SVG/pifss-logo-h.svg';
import { ReactComponent as PifssLogoSm } from '../assets/SVG/pifss-logo.svg';
import LogoutIcon from '@mui/icons-material/Logout';

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  marginLeft: 2,
  marginRight: 2,

  display: 'block',
  minWidth: 'max-content',
  textDecoration: 'none',
  '&.active': {
    color: theme.palette.primary.light,
  },
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const AppNav = () => {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const onLogout = (e) => {
    e.preventDefault();
    authStore.signout(navigate);
  };

  return (
    <AppBar
      component={Card}
      elevation={0}
      position="sticky"
      sx={{ background: '#eceff1' }}
    >
      <Box padding={2}>
        <Toolbar disableGutters>
          {/* Logo */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', lg: 'flex' },
              alignItems: 'start',
            }}
          >
            <Box
              component={PifssLogo}
              sx={{
                fill: theme.palette.primary.main,
                // width: '100%',
                height: '60px',
                display: { xs: 'none', lg: 'block' },
              }}
            />
          </Box>
          {/* menu */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', lg: 'none' },
            }}
          >
            {pages.length > 0 &&(<>
            
            <IconButton
              size="large"
              onClick={handleOpenNavMenu}
              sx={{ color: theme.palette.primary.main }}
              >
              <MenuRounded />
            </IconButton>
              
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', lg: 'none' },
              }}
              >
              {pages && pages?.map((page, Idx) => (
                <MenuItem key={Idx} onClick={handleCloseNavMenu}>
                  <NavLink to={page.path}>{page.name}</NavLink>
                </MenuItem>
              ))}
            </Menu>
              </>
            )}
          </Box>
          {/* Logo */}
          <Box
            sx={{
              display: { xs: 'block', lg: 'none' },
              flexGrow: 1,
            }}
          >
            <PifssLogoSm
              style={{
                fill: theme.palette.primary.main,
                height: '60px',
              }}
            />
          </Box>
          <Box
            sx={{
              flexGrow: 2,
              display: { xs: 'none', lg: 'flex' },
              gap: 4,
            }}
          >
            {pages?.map((page, Idx) => (
              <StyledNavLink
                key={Idx}
                onClick={handleCloseNavMenu}
                to={page.path}
              >
                {page.name}
              </StyledNavLink>
            ))}
          </Box>
          {/* logout */}
          <Box sx={{ flexGrow: 0 }}>
            {authStore.user && (
              <Button onClick={onLogout}>
                <Typography
                  style={{
                    color: theme.palette.primary.main,
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    minWidth: 'max-content',
                  }}
                >
                  تسجيل خروج <LogoutIcon color={'primary'} />
                </Typography>
              </Button>
            )}
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
};

export default AppNav;
