import React, {
  CSSProperties,
  FC,
  MouseEvent,
  ReactNode,
  useState,
} from 'react';

import theme from '../theme';

interface BtnProps {
  onSubmit?: (event: MouseEvent<HTMLButtonElement>) => void;
  children: ReactNode;
  outline?: boolean;
  borderRadius?: number;
  width?: number | string;
  padding?: number;
  alignSelf?: CSSProperties['alignSelf'];
  fullHeight?: boolean;
  deleteBtn?: boolean;
  disabled?: boolean;
}

const Btn: FC<BtnProps> = ({
  onSubmit,
  children,
  outline = false,
  borderRadius = 0,
  width = 200,
  padding = 10,
  alignSelf = 'center',
  fullHeight = false,
  deleteBtn = false,
  disabled = false,
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const defaultStyle: React.CSSProperties = {
    margin: 0,
    alignSelf,
    padding,
    width,
    outline: 'none',
    border: `1px solid ${
      disabled
        ? theme.palette.grey[500]
        : deleteBtn
        ? theme.palette.error.main
        : theme.palette.primary.main
    }`,
    background: outline
      ? 'transparent'
      : disabled
      ? theme.palette.grey[500]
      : deleteBtn
      ? theme.palette.error.main
      : theme.palette.primary.main,
    color: outline
      ? deleteBtn
        ? theme.palette.error.main
        : theme.palette.primary.main
      : '#fff',
    borderRadius,
    cursor: !disabled ? 'pointer' : 'not-allowed',
    height: fullHeight ? '100%' : 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
  };

  const hoverStyle: React.CSSProperties = {
    ...defaultStyle,
    background: outline
      ? deleteBtn
        ? theme.palette.error.light
        : theme.palette.primary.light
      : disabled
      ? theme.palette.grey[500]
      : deleteBtn
      ? theme.palette.error.light
      : theme.palette.primary.light,
    border: `1px solid ${
      disabled
        ? theme.palette.grey[500]
        : deleteBtn
        ? theme.palette.error.light
        : theme.palette.primary.light
    }`,
    color: '#fff',
  };

  return (
    <button
      type="submit"
      onClick={!disabled ? onSubmit : null}
      disabled={disabled}
      style={isHovered ? hoverStyle : defaultStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {children}
    </button>
  );
};

export default Btn;
