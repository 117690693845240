import { Route, Routes } from 'react-router-dom';

import React from 'react';
import Alert from './components/Alert';
import Loader from './components/Loader';
import HomePage from './pages/HomePage';
import { observer } from 'mobx-react';

function App() {
  return (
    <>
      <Loader />
      <Alert />
      <Routes>
        <Route path="/">
          <Route index element={<HomePage />} />
          <Route path="/*" element={<HomePage />} />
        </Route>
        <Route path="/*" element={<>404 Page Not Found</>} />
      </Routes>
    </>
    // <center>
    //   <AppNav />
    //   <h1>PIFSS-SP</h1>
    //   <h4>PIFSS Starting project</h4>
    // </center>
  );
}

export default observer(App);
