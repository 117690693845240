import React from 'react';
import { CameraAltOutlined, Close } from '@mui/icons-material';
import Scanner from './Scanner';
import verifyStore from '../stores/VerifyStore';
import Btn from '../components/Btn';
import { Box, Button, Modal, Stack } from '@mui/material';
import PageWrapper from '../components/PageWrapper';
import theme from '../theme';
import { observer } from 'mobx-react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '100%',
  maxHeight: '100%',
  overflow: 'auto',
  width: 'max-content',
  bgcolor: 'background.paper',
  border: `1px solid ${theme.palette.background.default}`,
  borderRadius: 2,
  boxShadow: 14,
  p: 1,
};

const QrScanner = () => {
  const handleOpen = () => {
    verifyStore.scan = true;
    verifyStore.open = true;
  };
  const handleClose = () => {
    verifyStore.open = false;
  };
  const button = (
    <Button variant="text" onClick={handleClose}>
      <Close />
    </Button>
  );
  return (
    <>
      <Btn borderRadius={15} onSubmit={handleOpen}>
        <CameraAltOutlined /> مسح QR
      </Btn>
      <Modal
        open={verifyStore.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack alignItems="end" sx={{ width: '100%' }} spacing={2}>
            <PageWrapper title="مسح QR" button={button}>
              <Scanner />
            </PageWrapper>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default observer(QrScanner);
