import { makeAutoObservable } from 'mobx';

// Define a type for the 'type' property
type AlertType = 'success' | 'error' | 'warning' | 'info';

class AlertsStore {
  // Define the properties with their types
  display: boolean = false;
  msg: string | null = null;
  type: AlertType | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  // Define the parameter types for the methods
  openSnackBar = (type: AlertType, msg: string): void => {
    this.display = true;
    this.msg = msg;
    this.type = type;
  };

  CloseSnackBar = (): void => {
    this.display = false;
  };
}

const alertsStore = new AlertsStore();
export default alertsStore;
