import { Box, Stack } from '@mui/material';

import AppNav from '../components/AppNav';
import React from 'react';
import VerifyRoutes from '../routes/VerifyRoutes';
import { observer } from 'mobx-react';

const HomePage = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#eceff1',
        height: '100vh',
        overflow: 'scroll',
      }}
    >
      <Stack height="100%">
        <AppNav />
        <VerifyRoutes />
      </Stack>
    </Box>
  );
};

export default observer(HomePage);
