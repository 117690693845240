import { makeAutoObservable } from 'mobx';
import decode, { JwtPayload } from 'jwt-decode';
import { instance, login } from './instance';
import alertsStore from './AlertsStore';
import { NavigateFunction } from 'react-router-dom'; // Import type for navigate
import userType from '../types/userType';

interface User extends JwtPayload {
  sub: string;
  // other properties
}

class AuthStore {
  loading = false;
  user: User | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setUser = (token: string, navigate?: NavigateFunction) => {
    this.loading = true;
    localStorage.setItem('token', token);
    instance.defaults.headers.common.Authorization = `Bearer ${token}`;
    this.user = decode<User>(token); // Specify User type here
    this.loading = false;
    navigate && navigate('/'); // Navigate if navigate is defined
  };

  checkForToken = () => {
    this.loading = true;
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = decode<User>(token);
      if (Date.now() > (decodedToken.exp || 0) * 1000) {
        // Ensure milliseconds comparison
        this.setUser(token);
      } else {
        this.signout();
      }
    }
    this.loading = false;
  };

  signin = async (userData: userType, navigate?: NavigateFunction) => {
    // Specify type for userData
    this.loading = true;
    try {
      const response = await login.post(
        '/LoginGetAuthorizationGroups',
        userData
      );
      const { token } = response.data;
      this.setUser(token, navigate);
      alertsStore.openSnackBar('success', `تم تسجيل دخول ${this.user?.sub}`);
    } catch (error) {
      localStorage.clear();
      console.error(error.message);
      // Handle errors appropriately
    }
    this.loading = false;
  };

  signout = (navigate?: NavigateFunction) => {
    this.loading = true;
    instance.defaults.headers.common.Authorization = null;
    this.user = null;
    localStorage.removeItem('token');
    navigate && navigate('/login'); // Navigate if navigate is defined
    this.loading = false;
  };
}

const authStore = new AuthStore();
authStore.checkForToken();

export default authStore;
