import { Box, Card } from '@mui/material';

import { QrScanner } from '@yudiel/react-qr-scanner';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import verifyStore from '../stores/VerifyStore';
import { observer } from 'mobx-react';

const Scanner = () => {
  const navigate = useNavigate();

  return (
    verifyStore.scan && (
      <Box
        sx={{
          width: 500,
          maxWidth: '100%',
          textAlign: 'center',
        }}
      >
        <Card elevation={5} sx={{ borderRadius: 3 }}>
          <QrScanner
            onDecode={(result) => {
              verifyStore.loading = true;
              verifyStore.scan = false;
              verifyStore.open = false;
              verifyStore.fetchQrData(result, navigate);
            }}
            onError={(error) => {
              console.log(error.message);
              verifyStore.scan = false;
              verifyStore.loading = false;
              verifyStore.open = false;
            }}
            videoStyle={{
              width: '100%',
              height: '100%',
              borderRadius: 10,
              backgroundColor: 'transparent',
              border: 'none',
            }}
            constraints={{ facingMode: 'environment' }}
          />
        </Card>
      </Box>
    )
  );
};

export default observer(Scanner);
