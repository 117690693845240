import { Route, Routes } from 'react-router-dom';

import React from 'react';
import Details from '../pages/Details';
import MainPage from '../pages/MainPage';

const VerifyRoutes = () => {
  return (
    <Routes>
      <Route index element={<MainPage />} />
      <Route path="details" element={<Details />} />
    </Routes>
  );
};

export default VerifyRoutes;
