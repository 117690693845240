import React, { useRef } from 'react';

import { UploadFileOutlined } from '@mui/icons-material';
import Btn from '../components/Btn';

/**
 * ImageUploader Success callback.
 *
 * @callback onFileSelectSuccess
 * @param {File} file - The image file.
 */

/**
 * ImageUploader Error callback.
 *
 * @callback onFileSelectError
 * @param {object} error - Error Object.
 * @param {string} error.error - Error Message.
 */

/**
 * ImageUploader
 * @param {object} props ImageUploader Props.
 * @param {onFileSelectSuccess} props.onFileSelectSuccess Success Handler.
 * @param {onFileSelectError} props.onFileSelectError The email of the user.
 */

const FileUploader = ({ onFileSelectSuccess, onFileSelectError }) => {
  const supportedFiles = ['application/pdf', 'image/png', 'image/jpeg'];

  // const supportedFileEnds = supportedFiles
  //   .map((file) => file.split('/')[1])
  //   .join(', ');

  /**
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e
   */

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if (supportedFiles.includes(file.type)) {
        onFileSelectSuccess(file);
      } else {
        onFileSelectError({ error: `Unsupported file type ${file.type}` });
      }
    }
  };

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleFileChange}
        accept={supportedFiles.join(',')}
      />
      <Btn borderRadius={15} onSubmit={handleButtonClick}>
        <UploadFileOutlined />
        رفع ملف
      </Btn>
    </>
  );
};

export default FileUploader;
