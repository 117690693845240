import { Box, Button, Modal, Stack } from '@mui/material';
import React, { ReactNode, useState } from 'react';

import { Close } from '@mui/icons-material';
import theme from '../theme';
import Btn from './Btn';
import PageWrapper from './PageWrapper';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '100%',
  maxHeight: '100%',
  overflow: 'auto',
  width: 'max-content',
  bgcolor: 'background.paper',
  border: `1px solid ${theme.palette.background.default}`,
  borderRadius: 2,
  boxShadow: 14,
  p: 1,
};

interface PopUpProps {
  title?: string;
  children: ReactNode;
  BtnLabel: string;
  BtnIcon?: ReactNode;
  BtnVariant?: 'outlined' | 'contained';
  onOpen?: () => void;
  onClose?: () => void;
}

const PopUp = ({
  BtnLabel,
  BtnIcon,
  BtnVariant = 'outlined',
  title,
  children,
  onOpen,
  onClose,
}: PopUpProps) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    onOpen && onOpen();
  };
  const handleClose = () => {
    setOpen(false);
    onClose && onClose();
  };
  const button = (
    <Button variant="text" onClick={handleClose}>
      <Close />
    </Button>
  );
  return (
    <>
      <Btn
        borderRadius={15}
        onSubmit={handleOpen}
        outline={BtnVariant === 'outlined'}
      >
        {BtnIcon && BtnIcon} {BtnLabel}
      </Btn>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack alignItems="end" sx={{ width: '100%' }} spacing={2}>
            <PageWrapper title={title} button={button}>
              {children}
            </PageWrapper>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default PopUp;
