// src\stores\VerifyStore.ts

import { scanFile } from '@openhealthnz-credentials/pdf-image-qr-scanner';
import axios from 'axios';
import { makeAutoObservable } from 'mobx';

import { SearchBody } from '../types/SearchBody';
import alertsStore from './AlertsStore';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const ERROR_MSG = 'حدث خطأ ما، تأكد من صحة البيانات';

class VerifyStore {
  loading = false;
  qrData = null;
  qrRes = null;
  uploadedFile = '';

  scan = false;
  open = false;

  constructor() {
    makeAutoObservable(this);
  }

  // Utility function for validation
  validateForm = (body: SearchBody): boolean => {
    const { Civil, Serial, RefNumber } = body;
    const regEx = /^[A-Za-z0-9]*$/;
    return (
      Civil?.length === 12 &&
      Serial?.length <= 15 &&
      !!RefNumber &&
      regEx.test(RefNumber)
    );
  };

  // Utility function for error handling
  handleError = (error, customMessage = ERROR_MSG) => {
    console.log('Error: ', error);
    alertsStore.openSnackBar('error', customMessage);
    this.loading = false;
  };

  processFile = async (selectedFile, navigate) => {
    this.loading = true;
    this.uploadedFile = '';
    try {
      const file = await scanFile(selectedFile);
      this.uploadedFile = file || 'لا يوجد بيانات للملف المرفق';
      if (this.uploadedFile !== 'لا يوجد بيانات للملف المرفق') {
        this.fetchQrData(file, navigate);
      }
    } catch (e) {
      this.handleError(e.response); // Refactored to use handleError
    }
  };

  search = async (body: SearchBody, navigate) => {
    this.loading = true;
    try {
      if (!this.validateForm(body)) {
        this.handleError(null, 'حدث خطأ ما، تأكد من صحة البيانات'); // Custom error message for validation failure
        return;
      }

      const res = await axios.post(`${BASE_URL}/GetQRVerificationById`, body);
      if (res.status === 200) {
        this.qrRes = res.data;
        navigate('/details');
      } else {
        this.handleError(new Error('Error! Status code: ' + res.status));
      }
    } catch (e) {
      this.handleError(e, e?.response?.data); // Handle unexpected errors
    } finally {
      this.loading = false;
    }
  };

  fetchQrData = async (result, navigate) => {
    try {
      const body = {
        qrString: result,
      };

      if (body.qrString.length > 150) {
        console.log(body);
        const res = await axios.post(BASE_URL + '/GetQRVerification/', body, {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        });

        if (res.data) {
          if (res.status === 200) {
            // Assuming 200 is the success status code
            this.qrRes = res.data;
            navigate('/details');
            alertsStore.openSnackBar('success', 'تم مسح بنجاح');
          } else {
            // Handle API response error
            this.handleError(new Error('Error! Status code: ' + res.status));
          }
        } else {
          this.handleError(new Error('Error! Status code: ' + res.status));
        }
      } else {
        this.handleError(
          new Error('تعذر قراءة البيانات، حاول مرة أخرى'),
          'تعذر قراءة البيانات، حاول مرة أخرى',
        );
      }
    } catch (e) {
      this.handleError(e, e.response.data); // Handle unexpected errors
    } finally {
      this.open = false;
      this.loading = false; // Ensure loading is set to false in all cases
    }
  };

  dlQrData = () => {
    try {
      if (typeof this.qrRes !== 'string') {
        throw new Error('Invalid QR data format');
      }

      // Attempt to decode the base-64 encoded string safely
      const base64Data = this.qrRes.replace(/_/g, '/').replace(/-/g, '+');
      const decodedData = atob(base64Data); // Decodes a base-64 encoded string

      // Convert decoded data to a Uint8Array
      const byteNumbers = new Array(decodedData.length);
      for (let i = 0; i < decodedData.length; i++) {
        byteNumbers[i] = decodedData.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      // Create a blob from the Uint8Array and specify the MIME type
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      // Generate a URL for the blob and open it in a new window
      const fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    } catch (error) {
      this.handleError(error);
    }
  };

  clearQrData = (navigate) => {
    try {
      this.loading = true;
      this.qrData = null;
      this.qrRes = null;
      navigate('/');
    } catch (e) {
      this.handleError(e); // Add error handling if necessary
    } finally {
      this.loading = false;
    }
  };
}

const verifyStore = new VerifyStore();
export default verifyStore;
