import { Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { SearchBody, SearchBodyErrors } from '../types/SearchBody';

import { useNavigate } from 'react-router-dom';
import Btn from '../components/Btn';
import TextInput from '../components/TextInput';
import verifyStore from '../stores/VerifyStore';

const Search = () => {
  const [body, setBody] = useState<SearchBody>({
    Civil: '',
    Serial: '',
    RefNumber: '',
  });
  const [errors, setErrors] = useState<SearchBodyErrors>({});
  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setBody((prev) => ({ ...prev, [name]: value }));
  };

  const validateForm = (): boolean => {
    const formErrors: SearchBodyErrors = {};
    let isValid = true;
    if (!body.Civil) {
      isValid = false;
      formErrors.Civil = 'يرجى ادخال الرقم المدني';
    }
    if (body.Civil) {
      if (body.Civil.length !== 12) {
        isValid = false;
        formErrors.Civil = 'الرقم المدني غير صحيح';
      }
    }
    if (!body.Serial) {
      isValid = false;
      formErrors.Serial = 'يرجى ادخال الرقم المسلسل';
    }
    if (body.Serial) {
      if (body.Serial.length > 15) {
        isValid = false;
        formErrors.Serial = 'الرقم المسلسل غير صحيح';
      }
    }
    if (!body.RefNumber) {
      isValid = false;
      formErrors.RefNumber = 'يرجى ادخال المرجع';
    }
    if (body.RefNumber) {
      const regEx = new RegExp(/^[A-Za-z0-9]*$/);
      if (!regEx.test(body.RefNumber)) {
        isValid = false;
        formErrors.RefNumber = 'رقم المرجع غير صحيح';
      }
    }
    setErrors(formErrors);
    return isValid;
  };

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (validateForm()) {
      verifyStore.search(body, navigate);
    }
  };

  return (
    <Stack
      width={700}
      maxWidth="100%"
      direction="column"
      gap={2}
      padding={4}
      // maxHeight="100%"
      // overflow="scroll"
    >
      <form onSubmit={onSubmit}>
        <Stack gap={2}>
          <TextInput
            name="Civil"
            onChange={handleChange}
            type="number"
            label="الرقم المدني"
          />
          <Typography variant="caption" color="error">
            {errors.Civil && errors.Civil}
          </Typography>

          <TextInput
            name="Serial"
            onChange={handleChange}
            label="الرقم المسلسل"
            type="number"
          />

          <Typography variant="caption" color="error">
            {errors.Serial && errors.Serial}
          </Typography>

          <TextInput
            name="RefNumber"
            onChange={handleChange}
            label="رقم المرجع"
          />

          <Typography variant="caption" color="error">
            {errors.RefNumber && errors.RefNumber}
          </Typography>
        </Stack>
      </form>
      <Btn onSubmit={onSubmit} borderRadius={15}>
        بحث
      </Btn>
    </Stack>
  );
};

export default Search;
