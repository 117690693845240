import { Stack, Typography } from '@mui/material';

import React from 'react';

interface TextInputProps {
  label?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  type?: string;
}

const TextInput = ({
  label,
  onChange,
  name,
  type = 'text',
}: TextInputProps) => {
  return (
    <Stack direction="column" spacing={2} width="100%">
      <Typography paddingRight={2}>{label}</Typography>
      <Stack
        direction="row"
        gap={5}
        sx={{
          background: '#fff',
          borderRadius: 5,
          overflow: 'hidden',
          paddingRight: 2,
          width: '100%',
          height: 50,
        }}
      >
        <input
          style={{
            height: 50,
            width: '100%',
            border: 'none',
            outline: 'none',
          }}
          name={name}
          type={type}
          onChange={onChange}
          autoFocus
        />
      </Stack>
    </Stack>
  );
};

export default TextInput;
