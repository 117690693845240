import { Divider, List, ListItem, ListItemIcon } from '@mui/material';

import { Circle } from '@mui/icons-material';
import React from 'react';

const InformationDetails = () => {
  const information = [
    'في حال استخدام الكاميرا لمسح رمز الـ QR وعند الضغط على المسح باستخدام الكاميرا، يرجى التأكد من الموافقة على استخدام الكاميرا من قبل الموقع.',
    'في حال استخدام الكاميرا لمسح رمز الـ QR ، يرجى ترك مسافة كافية بين الكاميرا والرمز وبوجود اضائة مناسبة لإتمام عملية المسح. ',
    'في حال استخدام شهادة مطبوعة، يرجى التأكد من استخدام شهادة مطبوعة بشكل صحيح وذلك لضمان التقاط رمز الـQR بشكل صحيح.',
  ];
  return (
    <List>
      {information?.map((info, Idx) => (
        <>
          <ListItem key={Idx} sx={{ textAlign: 'right' }}>
            <ListItemIcon>
              <Circle sx={{ width: 7 }} color="primary" />
            </ListItemIcon>
            {info}
          </ListItem>
          <Divider />
        </>
      ))}
      <ListItem sx={{ textAlign: 'start' }}>
        <ListItemIcon>
          <Circle sx={{ width: 7 }} color="primary" />
        </ListItemIcon>
        لتجربة افضل، يرجى استخدام المتصفحات التالية:
        <br />
        - Google Chrome
        <br />
        - Microsoft Edge
        <br />- Safari
      </ListItem>
    </List>
  );
};

export default InformationDetails;
