import { Snackbar } from '@mui/material';
import React from 'react';
import alertsStore from '../stores/AlertsStore';
import MuiAlert, { AlertColor } from '@mui/material/Alert';
import { observer } from 'mobx-react';

const Alert = () => {
  return (
    <Snackbar
      sx={{ alignItems: 'center', justifyContent: 'center' }}
      open={alertsStore.display}
      autoHideDuration={10000}
      defaultValue=""
      onClose={alertsStore.CloseSnackBar}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      <MuiAlert
        sx={{
          height: 50,
          justifyContent: 'center',
        }}
        variant="filled"
        severity={alertsStore.type as AlertColor}
      >
        {alertsStore.msg}
      </MuiAlert>
    </Snackbar>
  );
};

export default observer(Alert);
