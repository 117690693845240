const pages = [
  // {
  //   name: 'صفحة 1',
  //   path: '/',
  // },
  // {
  //   name: 'صفحة 2',
  //   path: '/page2',
  // },
];

export default pages;
