import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import React, { FC, ReactNode, useState } from 'react';

import theme from '../theme';

interface PageWrapperProps {
  title?: string;
  children: ReactNode;
  tabs?: string[];
  setCategoryNo?: (index: number) => void;
  button?: ReactNode;
}

const PageWrapper: FC<PageWrapperProps> = ({
  title,
  children,
  tabs,
  setCategoryNo,
  button,
}) => {
  const [sectionNo, setSectionNo] = useState(0);

  return (
    <Card
      elevation={0}
      sx={{ marginBottom: 3, width: '100%', padding: 1, borderRadius: 10 }}
    >
      {(title || tabs || button) && (
        <>
          <Grid container alignItems="center" paddingX={3}>
            <Grid item xs={10} md={6} lg={6} xl={6} padding={2}>
              <Typography
                color={theme.palette.primary.main}
                whiteSpace="nowrap"
                textOverflow="ellipsis"
              >
                {title}
              </Typography>
            </Grid>
            <Grid item xs={2} md={6} lg={6} xl={6}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="stretch"
                divider={<Divider orientation="vertical" flexItem />}
              >
                {tabs?.map((tab, Idx) => (
                  <Button
                    key={Idx}
                    sx={{
                      background:
                        sectionNo === Idx && theme.palette.secondary.light,
                      width: '100%',
                    }}
                    onClick={() => {
                      setSectionNo(Idx);
                      if (setCategoryNo) setCategoryNo(Idx); // Check existence before calling
                    }}
                  >
                    {tab}
                  </Button>
                ))}
                {button && button}
              </Stack>
            </Grid>
          </Grid>
          <Divider />
        </>
      )}
      <Box paddingX={5} paddingTop={2}>
        {children}
      </Box>
    </Card>
  );
};

export default PageWrapper;
